<template>
  <Layout tituloPagina="Hotspot | Fichas | Tipo">
    <div class="row">
      <div class="col-lg-4">
        <cmp-secciones seccion="tipo-fichas"></cmp-secciones>
      </div>
    </div>
    <div class="row">
      <div class="offset-md-2 col-md-8 col-9 mb-3">
        <filtrador
          v-on:dato-actualizado="
            dato = $event,
            refrescarFichasTipos()
          "
          :datoini="dato"
          placeholder="Dato del tipo de ficha"
        ></filtrador>
      </div>
      <div class="col-md-2 col-3 text-right">
        <router-link :to="{ name: 'nuevoFichaTipoHotspot' }" class="btn btn-success">
          <span class="d-none d-sm-block">
            <i class="mdi mdi-plus-thick"></i>
            Nuevo tipo fichas
          </span>
          <span class="d-block d-sm-none">
            <i class="mdi mdi-plus-thick"></i>
          </span>
        </router-link>
      </div>
    </div>
    <br>
    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">
          Listado tipo de fichas
          <small class="font-size-10">
            Aquí puedes ver los tipos de fichas registrados
          </small>
        </h4>
      </div>
      <div class="card-body" style="min-height: 475px;">
        <div class="table-responsive">
          <table class="tblRouters table table-hover align-middle table-nowrap">
            <thead>
              <tr>
                <th>ID</th>
                <th style="width: 500px">Descripción</th>
                <th>Precio</th>
                <th style="min-width: 150px">Fecha de creación</th>
                <th style="min-width: 150px">Ultima actualización</th>
                <th>Perfil</th>
                <th style="width: 190px" class="text-center">Acción</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="tipo in fichas_tipos" :key="tipo.id">
                <td class="fw-semibold">{{ tipo.id}}</td>
                <td>{{ tipo.descripcion }}</td>
                <td class="text-success">
                  <strong>{{ formatoMoneda(tipo.precio) }}</strong>
                </td>
                <td>
                  {{ formatoFecha(tipo.created_at) }}
                </td>
                <td>
                  {{ formatoFecha(tipo.updated_at) }}
                </td>
                <td class="fw-semibold">
                  {{tipo.nombre_perfil}}
                </td>
                <td class="text-center">
                  <router-link
                    class="btn btn-warning btn-sm"
                    :to="{
                      name:'edicionFichaTipoHotspot',
                      params:{
                        id:tipo.id
                      }
                    }"
                  >
                    <i class="mdi mdi-content-save-edit-outline"></i>
                    Edición
                  </router-link>
                  <button
                    class="btn btn-danger btn-sm"
                    @click="eliminarFichaTipo(tipo)"
                  >
                    <i class="mdi mdi-trash-can-outline"></i>
                    Eliminar
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="text-center">
      <paginador
        :pag="page"
        :totpag="totpag"
        :epp="epp"
        @cargar-pagina="cargarPagina"
        @epp-actualizado="
          epp = $event,
          refrescarFichasTipos()
        "
      ></paginador>
    </div>
    <br>
  </Layout>
</template>

<script>
import CmpSecciones from '../CmpSecciones.vue'
import Paginador from '@/components/Paginador.vue'
import Filtrador from '@/components/Filtrador.vue'
import accounting from 'accounting/accounting.js'

import FichaTipoSrv from '@/services/hotspot/FichaTipoSrv.js'
import moment from 'moment'
import Layout from "@/views/layouts/main"
import Swal from 'sweetalert2'
export default {
  name: 'Listado',
  components: {
    Layout,
    CmpSecciones,
    Paginador,
    Filtrador
  },
  data() {
    return {
      dato: '',
      page: 1,
      epp: parseInt(localStorage.getItem('argusblack.listado.por_pagina') || 10),
      tc: 0, // Total de portales cautivos
      totpag: 0, // Total de páginas
      fichas_tipos:[]
    }
  },
  computed: {
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    }
  },
  created : function () {
    var self = this

    if (self.$route.query.dato) self.dato = self.$route.query.dato
    if (self.$route.query.page) self.page = parseInt(self.$route.query.page)
    if (self.$route.query.epp)  self.epp  = parseInt(self.$route.query.epp)

    self.refrescarFichasTipos()
  },
  methods: {
    cargarPagina: function(n) {
      var self = this
      self.page = n
      self.refrescarFichasTipos({ page: n })
    },

    formatoFecha(mt) {
      return moment(mt, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
    },   

    formatoMoneda: function(numero) {
      return accounting.formatMoney(numero, { symbol: this.monedaSistema.simbolo, miles: ",", decimal: "." })+' '+this.monedaSistema.codigo
    },

    eliminarFichaTipo: function(tipo) {
      var self = this
      
      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "¿Eliminar la ficha tipo?",
        html: "¿Está seguro de eliminar la ficha tipo <br><b>"+tipo.descripcion+"</b>?",
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          FichaTipoSrv.eliminar(tipo.id).then(response => {
            swal.fire("Eliminado!", "Ficha tipo "+'"'+tipo.descripcion+'"'+" eliminado.", "success");
            self.refrescarFichasTipos()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se puede eliminar la ficha tipo "'+tipo.descripcion+'"'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    refrescarFichasTipos: function(){
      var self = this

      self.$router.replace({
        query: {
          dato: self.dato,
          page: self.page,
          epp: self.epp
        }
      }).catch(error => {})

      let params = {
        dato: self.dato,
        page: self.page,
        epp: self.epp
      }

      FichaTipoSrv.fichasTiposJSON(params).then(response => {
        localStorage.setItem('argusblack.listado.por_pagina', self.epp)
        let page = response.data
        self.fichas_tipos = page.data
        self.tc = page.total
        self.totpag = page.last_page

        if (self.totpag < self.page) {
          self.page = self.totpag
          self.refrescarFichasTipos()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (error) {
          mensaje = "No se pudieron cargar las fichas"
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    }
  },
}
</script>

<style scoped>

</style>